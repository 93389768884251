import React from "react";
import { InputContainer } from "@msidentity/sisu/components/inputs/input-container";
import { TextInput } from "@msidentity/SISU/components/inputs/text-input";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { PhoneNumber } from "@msidentity/SISU/components/phone-number";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "@msidentity/SISU/components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "@msidentity/sisu/components/title/fabric/title-fabric";
import { FlowId, ViewId } from "@msidentity/sisu/constants/routing-constants";
import globalConfig from "@msidentity/sisu/global-config";
import { useActivateView } from "@msidentity/sisu/hooks/use-activate-view";
import { useDocumentTitle } from "@msidentity/SISU/hooks/use-document-title";
import useFabricStyles, {
  useStaticFabricStyles,
} from "@msidentity/SISU/styles/fabric/fabric.styles";
import { getDefaultCountry, parseCountryList } from "@msidentity/sisu/utilities/country-helper";
import {
  configWrapper as ResetPasswordConfig,
  countryPrefixList,
} from "../../../reset-password-config";
import { useRecoverAccount } from "../hooks/use-recover-account";
import { recoverAccountStringsFabric as strings } from "./recover-account-strings-fabric";

/**
 * RecoverAccountView
 * @returns RecoverAccountView
 */
export const RecoverAccountViewFabric: React.FC = function RecoverAccountViewFabric() {
  useActivateView(ViewId.ResetPasswordSignInName, FlowId.ResetPasswordSignInName, {
    showIdentityBanner: false,
  });

  useDocumentTitle(strings.title);
  useStaticFabricStyles();

  const { resetPwdAction, phoneCountry } = ResetPasswordConfig.instance;
  const { returnUrl, postUrl, canaryTokenValue, canaryTokenName } = globalConfig.instance;

  const {
    value,
    isPhoneNumberMode,
    error,
    onSubmit,
    onValueChange,
    errorCode,
    onPhoneNumberChange,
    onUseEmailClickHandler,
    onCancelClickHandler,
    blurHandler,
    hasFocus,
    focusHandler,
  } = useRecoverAccount(strings);

  const fabricStyles = useFabricStyles();
  const countryData = parseCountryList(countryPrefixList, true);
  const defaultCountryData = getDefaultCountry(countryData, phoneCountry);
  const description = isPhoneNumberMode ? strings.phoneNumberMsg : strings.description;

  return (
    <form
      id="resetPwdForm"
      name="resetPwdForm"
      method="post"
      noValidate
      action={postUrl}
      data-testid="resetPwdForm"
      onSubmit={onSubmit}
    >
      <TitleFabric
        title={strings.title}
        description={description}
        titleId="iResetPwdHipTitle"
        descriptionId="iExplanationText"
      />

      <input type="hidden" id="iAction" name="iAction" value={resetPwdAction} />
      <input type="hidden" id="iRU" name="iRU" value={returnUrl} />
      <input
        type="hidden"
        id="isSigninNamePhone"
        name="isSigninNamePhone"
        value={isPhoneNumberMode ? "true" : "false"}
      />
      <input type="hidden" id="canary" name={canaryTokenName} value={canaryTokenValue} />
      {errorCode && <input type="hidden" id="iErrorCode" name="iErrorCode" value={errorCode} />}

      {isPhoneNumberMode ? (
        <>
          <PhoneNumber
            errorMessage={error}
            phoneNumberChangeHandler={onPhoneNumberChange}
            dropdownProps={{
              useInlinePhoneNumber: true,
              defaultCountryData,
              countryData,
            }}
            inputProps={{
              value,
              showErrorInline: true,
              externalError: error,
              id: "iSigninName",
              name: "iSigninName",
              placeholder: strings.placeHolderPhoneNumber,
              type: "tel",
              hasFocus,
              focusHandler,
              blurHandler,
              hasInitialFocus: true,
            }}
          />
          <div className={fabricStyles.row}>
            <div className={fabricStyles.formGroup}>
              <LinkButton
                linkId="iUsePhoneOrEmailLink"
                text={strings.useEmailOrSkypeLinkText}
                onClick={onUseEmailClickHandler}
              />
            </div>
          </div>
        </>
      ) : (
        <TextInput
          id="iSigninName"
          name="iSigninName"
          type="email"
          ariaLabelledBy="iResetPwdHipTitle"
          ariaDescribedBy="iExplanationText"
          placeholder={strings.textInputPlaceHolder}
          showErrorInline
          externalError={error}
          value={value}
          blurHandler={blurHandler}
          focusHandler={focusHandler}
          changeHandler={(e) => onValueChange(e.target.value)}
          hasInitialFocus
          hasFocus={hasFocus}
        />
      )}

      <InputContainer>
        <div className={fabricStyles.row}>
          <TextButtonContainer>
            <TextButtonFabric
              label={strings.cancelBtn}
              buttonId="resetPwdHipCancel"
              ariaLabelledBy="iResetPwdHipTitle"
              ariaDescribedBy="iExplanationText"
              onClick={onCancelClickHandler}
            />
            <TextButtonFabric
              isButtonType={false}
              label={strings.submitBtn}
              isPrimary
              buttonId="resetPwdHipAction"
              ariaLabelledBy="iResetPwdHipTitle"
              ariaDescribedBy="iExplanationText"
            />
          </TextButtonContainer>
        </div>
      </InputContainer>
    </form>
  );
};
