import { type IRecoverAccountStrings } from "../../../reset-password-interface";

export const recoverAccountStringsFabric: IRecoverAccountStrings = {
  title: getLocalString("PageTitle_ResetPassword_HIP_Short"),
  description: getLocalString("PWRHIP_Explanation"),
  textInputPlaceHolder: getLocalString("FieldLabel_EmailPhoneSkype"),
  validationErrorMessage: getLocalString("Error_InvalidMemberName_EmailPhoneSkype"),
  validationErrorMessagePhone: getLocalString("Controls_PhoneClientValidation_InvalidError"),
  proofMismatchErroMessage: getLocalString("OwnershipChallenge_ProofMismatch_Error_Email"),
  cancelBtn: getLocalString("General_Buttons_Cancel"),
  phoneNumberMsg: getLocalString("AccountRecovery_ConfirmPhone_Message"),
  submitBtn: getLocalString("General_Buttons_Next"),
  useEmailOrSkypeLinkText: getLocalString("AccountRecovery_UseEmailOrSkype_ButtonText"),
  resetPasswordTitle: getLocalString("PageTitle_ResetPassword"),
  serverErrorMessage: getLocalString("Errors_Account_Not_Found"),
  placeHolderPhoneNumber: getLocalString("General_PhoneNumber"),
};
