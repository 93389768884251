import React from "react";
import { Route } from "react-router-dom";
import RouteAnimation from "@msidentity/SISU/components/route-animation";
import { RecoverAccountViewFabric as RecoverAccountView } from "../views/recover-account/fabric/recover-account-view-fabric";

/**
 * ResetPwdSignInNameFlow component
 * @returns ResetPwdSignInNameFlow
 */
export const ResetPwdSignInNameFlowFabric: React.FC = function ResetPwdSignInNameFlowFabric() {
  return (
    <RouteAnimation>
      <Route path="*" element={<RecoverAccountView />} />
    </RouteAnimation>
  );
};
