import React from "react";
import ReactDOM from "react-dom";
import { MemoryRouter } from "react-router-dom";
import Shell from "@msidentity/SISU/components/shell/fabric/shell-fabric";
import { Flavors } from "@msidentity/SISU/constants";
import { initGlobalConfig } from "@msidentity/SISU/global-config";
import { createGlobalState, GlobalProvider } from "@msidentity/SISU/global-context";
import { ResetPwdSignInNameFlowFabric } from "../../flows/reset-password/fabric/reset-pwd-signin-name-flow-fabric";
import { initResetPasswordConfig } from "../../flows/reset-password/reset-password-config";

/**
 * The app encapsulating the first page in the reset password flow where we collect the
 * username for the account whose password needs to be reset. Once the user enters the username,
 * there is a POST redirect back to ALC's reset password endpoint with relevant input params around
 * the username. This step will be skipped entirely if user comes to reset password by clicking on
 * the 'Forgot password' link on Login. TODO: This can be combined into a single app for reset
 * password but that requires server updates.
 * @returns a react component that renders the first sign in view of the reset password flow
 */
export const PasswordResetAppFabric: React.FC = function PasswordResetAppFabric() {
  const serverData = window.ServerData;
  const initialGlobalState = createGlobalState(serverData, Flavors.Fabric);
  initGlobalConfig(serverData);
  initResetPasswordConfig(serverData);

  return (
    <React.StrictMode>
      <MemoryRouter>
        <GlobalProvider initialState={initialGlobalState}>
          <Shell>
            <ResetPwdSignInNameFlowFabric />
          </Shell>
        </GlobalProvider>
      </MemoryRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<PasswordResetAppFabric />, document.getElementById("root"));
