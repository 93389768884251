import {
  AltEmailRegEx,
  PhoneNumberFormat,
  SkypeRegex,
} from "@msidentity/SISU/components/inputs/input-regex-constants";
import { type ICountryInfo } from "@msidentity/SISU/utilities/country-helper";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import {
  type IProof,
  type IProofMethod,
  type ISelectProofStrings,
  ProofMethods,
} from "./reset-password-interface";

export const isValidEmailFormat = (signinName: string) => !!signinName.match(AltEmailRegEx);
export const isValidSkypeFormat = (signinName: string) => !!signinName.match(SkypeRegex);
export const isValidPhoneFormat = (signinName: string) => !!signinName.match(PhoneNumberFormat);

export const isValidSignInName = (signinName: string): boolean =>
  isValidEmailFormat(signinName) ||
  isValidSkypeFormat(signinName) ||
  isValidPhoneFormat(signinName);

export const getProofDisplayName = (proof: IProof) => {
  if (proof.type === ProofMethods.AlternateAuthenticator) return getLocalString("Alternate_Authenticator_App");
  if (proof.type === ProofMethods.Authenticator)
    return getLocalString("SetupAuthenticator_Authenticator_App_Name");
  return proof.name;
};

export const isLowCostOrUsedProof = (proof: IProofMethod) =>
  proof.type !== ProofMethods.SMS || !!proof.used;

export const extractNLastPhoneDigits = (phoneNumber: string, n: number) =>
  phoneNumber.substring(phoneNumber.length - n, phoneNumber.length);

export const extractEmailDomain = (email: string) => {
  if (!email.includes("@")) {
    return email;
  }

  return email.substring(email.indexOf("@"), email.length);
};

export const obfuscateEmail = (email: string) => {
  if (!email.includes("@")) {
    return email;
  }

  const emailDomain = extractEmailDomain(email);
  const obfuscatedEmail = `${email.substring(0, 2)}*****${emailDomain}`;
  return obfuscatedEmail;
};

export const obfuscatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length <= 2) {
    return phoneNumber;
  }

  let obfuscatedPhoneNumber = "";

  for (let i = 0; i < phoneNumber.length - 2; i += 1) {
    obfuscatedPhoneNumber += "*";
  }

  obfuscatedPhoneNumber += phoneNumber.substring(phoneNumber.length - 2, phoneNumber.length);
  return obfuscatedPhoneNumber;
};

export const validateEmailInitials = (input: string, email: string) => {
  const emailInitials = email.substring(0, 2);
  const inputInitials = input.substring(0, 2);

  return inputInitials === emailInitials;
};

export const validateLastPhoneNumberDigits = (input: string, phoneNumber: string) =>
  extractNLastPhoneDigits(input, 2) === extractNLastPhoneDigits(phoneNumber, 2);

export const getVerificationProofText = (
  proof: IProofMethod,
  selectProofStrings: ISelectProofStrings,
  isSecondVerification: boolean = false,
) => {
  let proofString: string;

  switch (proof.type) {
    case ProofMethods.Authenticator:
      proofString = selectProofStrings.proofOptionsAuthenticator;
      break;
    case ProofMethods.SecurityQuestion:
      proofString = selectProofStrings.proofOptionsSecurityQuestion;
      break;
    case ProofMethods.Email:
      proofString = replaceTokens(selectProofStrings.proofOptionsEmail, proof.name);
      break;
    case ProofMethods.SMS:
      proofString = replaceTokens(selectProofStrings.proofOptionsSMS, proof.name);
      break;
    default:
      proofString = proof.name;
      break;
  }

  if (isSecondVerification && !!proof.used) {
    return replaceTokens(selectProofStrings.proofPickerAlreadyUsed, proofString);
  }

  return proofString;
};

/**
 *  Format phone number
 * @param nationalNumber National numebr
 * @param country Country data
 * @returns String representation of the supplied phone number
 */
export const getFullyQualifiedPhoneNumber = (
  nationalNumber: string,
  country?: ICountryInfo | null,
): string => {
  const trimmedNumber = nationalNumber.trim();

  let countryPrefix = "";
  if (trimmedNumber.charAt(0) === "+") {
    countryPrefix = "+";
  } else if (country) {
    countryPrefix = `+${country.code}`;
  }

  return `${countryPrefix}${trimmedNumber.replace("+", "")}`;
};

/**
 * Maps error code to error message
 * @param errorCode Error code returned by the service
 * @returns Error message to be displayed to the user
 */
export const mapResponseError = (errorCode: string): string => {
  const errorMessages: { [id: string]: string; } = {
    "1001": getLocalString("Errors_Account_Not_Found"),
    "1007": getLocalString("Help_password_short"),
    "1009": getLocalString("Password_Use_Error"),
    "1008": getLocalString("Password_Not_Allowed_Error"),
    "1012": getLocalString("Security_Answer_In_Password_Error"),
    "1014": getLocalString("Security_Question_In_Password_Error"),
    "1017": getLocalString("Generic_Task_Unavailable_Error"),
    "1088": getLocalString("Password_Alias_Error"),
    "1217": getLocalString("Password_Already_Seen_Error"),
    "1218": getLocalString("Old_Password_Error"),
    "1281": getLocalString("Password_Change_Error"),
    "6001": getLocalString("Password_Change_Error"),
  };
  
  const errorMessage = errorMessages[errorCode] || getLocalString("Generic_Service_Error");
  return errorMessage;
};
